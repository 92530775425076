// src/api/endpoints.js

export const USERS_ENDPOINT = 'users';
export const BLOG_ENDPOINT = '/items/blogs';
export const STU_REVIEW_ENDPOINT = '/items/student_review';
export const MAIL_INQUIRY_ENDPOINT = '/items/mail_inquiry';
export const COURSE_MATERIAL_ENDPOINT = '/items/course_materials';
export const COURSE_MATERIAL_SUB_TOPIC_ENDPOINT = '/items/course_materials_sub_topic';
export const STUDENT_API_ENDPOINT = '/items/student';
export const USER_API_ENDPOINT = '/users';
export const FILE_UPLOAD_ENDPOINT = '/files';