import React from "react";
import CommonHeading from "../common/CommonHeading";
import { courses } from "../data/Data";
import ImageLoader from "../common/ImageLoader";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function PopularCourses() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <CommonHeading
            heading="Popular Courses"
            title="Courses"
            subtitle="POPULAR"
          />
        </div>
        <div className="row g-4">
          {courses.map((item, index) => (
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
              key={index}
            >
              <div
                className="course-card shadow-sm"
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  border: "1px solid #ddd",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {/* Course Image and Title */}
                <div style={{ position: "relative" }}>
                  <ImageLoader
                    imageId={item.img}
                    style={{
                      width: "100%",
                      height: "180px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "15px",
                      left: "15px",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    {item.name}
                  </div>
                  <MoreVertIcon
                    style={{
                      position: "absolute",
                      top: "15px",
                      right: "15px",
                      color: "white",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>

                {/* Course Description */}
                <div className="p-3" style={{ flexGrow: 1 }}>
                  <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                    {item.description.length > 200
                      ? `${item.description.substring(0, 200)}...`
                      : item.description}
                  </p>
                </div>

                {/* Course Name and See More Button Row */}
                {/* Course Name and See More Button Row */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 10px 10px",
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    {item.name}
                  </span>
                  <a
                    className="btn"
                    style={{
                      backgroundColor: "#004380",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "2px 5px",
                    }}
                    href={`/course?courseCode=${item.courseCode}`}
                  >
                    See More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
