import React from "react";
import Countdown from "react-countdown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { examItems } from "../data/Data";

export default function Exams() {
  const countdownRenderer = ({ days, hours, minutes, seconds }) => (
    <div className="exam-countdown">
      <div className="countdown-unit">
        <div className="countdown-number">{days}</div>
        <div className="countdown-label">DAYS</div>
      </div>
      <div className="countdown-unit">
        <div className="countdown-number">{hours}</div>
        <div className="countdown-label">HOURS</div>
      </div>
      <div className="countdown-unit">
        <div className="countdown-number">{minutes}</div>
        <div className="countdown-label">MINUTES</div>
      </div>
      <div className="countdown-unit">
        <div className="countdown-number">{seconds}</div>
        <div className="countdown-label">SECONDS</div>
      </div>
    </div>
  );
  return (
    <div className="container-xxl exams">
      <div className="row">
        {examItems.map((item, key) => (
          <div
            key={key}
            className="col-lg-5 col-md-6 col-sm-12 mx-auto my-3 wow fadeInUp text-bold exam-card"
            data-wow-delay="0.1s"
            style={{
              borderRadius: "20px",
              background:
                "linear-gradient(160.14deg, #64A2D5 2.35%, #004380 86.73%)",
            }}
          >
            <div className="p-4">
              <div className="position-relative">
                <div className="d-flex justify-content-center text-center mb-3">
                  <h5
                    className="mb-0"
                    style={{
                      color: "#022044",
                      fontSize: "30px",
                    }}
                  >
                    {item.name}
                  </h5>
                </div>
                <img
                  className="img-fluid"
                  src={item.img}
                  alt="Exam category"
                  style={{ borderRadius: "20px", width: "100%" }}
                />
              </div>
              <div className="p-4 mt-2">
                <div className="exam-list-view">
                  {item.exams.map((exam) => (
                    <div
                      key={exam.id}
                      className="exam-item d-flex align-items-center justify-content-between mb-3"
                    >
                      <div className="exam-details d-flex align-items-center">
                        <CalendarTodayIcon className="exam-icon text-white me-2" />
                        <div className="exam-info">
                          <h5 className="exam-title text-white mb-0">
                            {exam.title}
                          </h5>
                          <p className="exam-date text-white-50 mb-0">
                            {exam.date}
                          </p>
                        </div>
                      </div>
                      <Countdown
                        date={exam.countdownDate}
                        renderer={countdownRenderer}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
