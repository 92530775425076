import React from "react";

const BookSpinner = () => {
  return (
    <div className="book-spinner">
      <div className="pages">
        <div className="page"></div>
        <div className="page"></div>
        <div className="page"></div>
      </div>
      <p className="loading-text">Loading...</p>
    </div>
  );
};

export default BookSpinner;
