import { Description } from "@mui/icons-material";

export const navList = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/about",
    text: "About",
  },
  {
    id: 4,
    path: "/course-materials",
    text: "Course Materials",
  },
  {
    id: 6,
    path: "/page",
    text: "Courses",
    subItems: [
      {
        id: 51,
        path: "/course?courseCode=EasterCrash",
        text: "Easter Crash Course",
      },
      {
        id: 52,
        path: "/course?courseCode=KS4",
        text: "KS4 Maths Course",
      },
      {
        id: 53,
        path: "/course?courseCode=ALMaths12",
        text: "AL Maths Course 12",
      },
      {
        id: 54,
        path: "/course?courseCode=ALMaths13",
        text: "AL Maths Course 13",
      },
      // {
      //   id: 55,
      //   path: "/course?courseCode=KS3Master",
      //   text: "KS3 Master Course",
      // },
    ],
  },
  {
    id: 3,
    path: "/blogs",
    text: "Blogs",
  },
  {
    id: 7,
    path: "/contact",
    text: "Contact Us",
    // subItems: [
    //   {
    //     id: 56,
    //     path: "/contact",
    //     text: "Contact",
    //   },
    //   {
    //     id: 57,
    //     path: "/team",
    //     text: "Our Team",
    //   },
    // ],
  },
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
  },
  {
    icon: <i className="fab fa-twitter"></i>,
  },
  {
    icon: <i className="fab fa-instagram"></i>,
  },
  {
    icon: <i className="fab fa-linkedin-in"></i>,
  },
  {
    icon: <i className="fab fa-youtube"></i>,
  },
];

export const carouselData = [
  {
    img: "../assets/img/homebg.png",
    title: "Start your GCSE & A Level journey with us today",
    subtitle:
      "Unlock Your Potential: Master Maths & Science with Expert Tutors by Your Side. Achieve academic excellence, boost confidence, and unleash your true potential.",
    btn1: "JOIN WITH US",
    btn2: "LET'S GET IN TOUCH",
  },
  {
    img: "../assets/img/homebg.png",
    title: "Start your GCSE & A Level journey with us today",
    subtitle:
      "Your journey to GCSE & A Level success starts here. Access quality resources, study at your own pace, and reach new academic heights.",
    btn1: "JOIN WITH US",
    btn2: "LET'S GET IN TOUCH",
  },
];
export const about = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary mb-2"></i>,
    text: "Exams",
    count: "7861",
  },
  {
    icon: <i class="fa fa-users fa-2x text-primary mb-2"></i>,
    text: "Staffs",
    count: "1234",
  },
  {
    icon: <i class="fa fa-users-cog fa-2x text-primary mb-2"></i>,
    text: "Clients",
    count: "4321",
  },
];

export const educationQuality = [
  {
    icon: <i className="fa fa-search fa-2x" style={{ color: "white" }}></i>,
    name: "Find Course",
    description:
      "Find what do you want to learn by searching class name, category or instructor name’s.",
    background: "linear-gradient(137.36deg, #EB5757 -12.5%, #C72929 126.8%)",
  },
  {
    icon: <i className="fa fa-video fa-2x" style={{ color: "white" }}></i>,
    name: "Watch Video",
    description:
      "Watch all the video on the class that you already in and take a note for it to gain  more knowledge.",
    background: "linear-gradient(137.36deg, #9B51E0 -12.5%, #581893 126.8%)",
  },
  {
    icon: <i className="fa fa-chart-bar fa-2x" style={{ color: "white" }}></i>,
    name: "Watch Video",
    description:
      "To prove that you passed the class, try the assignments that your teacher made and get the best score.",
    background: "linear-gradient(137.36deg, #28E076 -12.5%, #0CB955 126.8%)",
  },
  {
    icon: <i className="fa fa-cog fa-2x" style={{ color: "white" }}></i>,
    name: "Get Best Result",
    description:
      "Well done! you have proved that you already understand about every subject on class",
    background: "linear-gradient(137.36deg, #5FC3F2 -12.5%, #3A81A3 126.8%)",
  },
];

export const blogs = [
  {
    img: "785492b6-50e7-4954-9df5-054cac9e1853",
    name: "Quantum Physics",
    userImg: "../assets/img/testimonial-1.jpg",
    userName: "Dr. Alice Smith",
    profession: "Physicist",
    description:
      "An introduction to the mysterious world of quantum mechanics and its implications for modern technology.",
  },
  {
    img: "1da39828-9730-4bb4-983c-99d278a4937c",
    name: "Organic Chemistry",
    userImg: "../assets/img/testimonial-2.jpg",
    userName: "Prof. John Doe",
    profession: "Chemist",
    description:
      "Exploring the fascinating structures and reactions of organic compounds in the field of chemistry.",
  },
  {
    img: "64c9b022-788d-4c39-95fa-a14c95d4fd87",
    name: "Astrophysics",
    userImg: "../assets/img/testimonial-3.jpg",
    userName: "Dr. Maria Chen",
    profession: "Astrophysicist",
    description:
      "Discover the fundamental principles of astrophysics and how they help us understand the universe.",
  },
];
export const team = [
  {
    image: "../assets/img/team-1.jpg",
    name: "Full Name",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-2.jpg",
    name: "Full Name",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Full Name",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Full Name",
    designation: "Designation",
  },
];

export const footerItem = [
  {
    id: 1,
    header: "Quick Link",
    UnitItem: [
      {
        name: "Home",
      },
      {
        name: "About",
      },
      {
        name: "Blogs",
      },
      {
        name: "Courses",
      },
      {
        name: "Course Materials",
      },
      {
        name: "Contact Us",
      },
    ],
  },
];

export const footerContact = [
  // {
  //   icon: <i className="fa fa-map-marker-alt me-3"></i>,
  //   name: "Desford Rd, Leicester LE19 4AT, UK",
  // },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "+44 07800 813943",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "ashani.gamage@gmail.com",
  },
];

export const contact = [
  // {
  //   icon: <i className="fa fa-map-marker-alt text-primary me-2"></i>, // For address
  //   value: "Desford Rd, Leicester LE19 4AT, UK",
  // },
  {
    icon: <i className="fa fa-phone-alt text-primary me-2"></i>, // For phone number
    value: "+44 078 0081 3943",
  },
  {
    icon: (
      <i className="fa fa-envelope text-primar1055 Arthur Ave, Elk Grove, New Palmas, South Carolina.y me-2"></i>
    ), // For email
    value: "ashani.gamage@gmail.com",
  },
];

export const examItems = [
  {
    img: "../assets/img/examcard1.png", // Image for GCSE Exams
    name: "Upcoming GCSE Exams",
    exams: [
      {
        id: 1,
        title: "GCSE Paper 1",
        date: "Wednesday 15 May 2025 (AM)",
        countdownDate: new Date("2025-05-15T08:00:00"),
      },
      {
        id: 2,
        title: "GCSE Paper 2",
        date: "Friday 4 June 2025 (AM)",
        countdownDate: new Date("2025-06-04T08:00:00"),
      },
      {
        id: 3,
        title: "GCSE Paper 3",
        date: "Monday 11 June 2025 (AM)",
        countdownDate: new Date("2025-06-11T08:00:00"),
      },
    ],
  },
  {
    img: "../assets/img/examcard2.png", // Image for AL Exams
    name: "Upcoming AL Exams",
    exams: [
      {
        id: 4,
        title: "AL Paper 1:Pure Mathematics-1",
        date: "Wednesday 04 June 2025 (AM)",
        countdownDate: new Date("2025-05-04T08:00:00"),
      },
      {
        id: 5,
        title: "AL Paper 2:Pure Mathematics-2 ",
        date: "Friday 12 June 2025 (AM)",
        countdownDate: new Date("2025-05-12T08:00:00"),
      },
      {
        id: 6,
        title: "AL Paper 3:Statistics & Mechanics",
        date: "Monday 19 June 2025 (AM)",
        countdownDate: new Date("2025-06-19T08:00:00"),
      },
    ],
  },
];

//Course details
export const courses = [
  {
    img: "aa49c7fd-1d37-4811-b3a3-d30e42ee6a25",
    rightImage : "d7cdea45-ee03-489d-89b3-73c0618b498d",
    bgColor : "#27AE60",
    name: "EASTER CRASH",
    courseCode: "EasterCrash",
    description:
      "Refresher Unlock Your Potential Gear up for success with our GCSE Easter Revision Course, specifically designed for those looking to solidify their understanding of key maths concepts",
    title: "Easter Crash Course",
    sections: [
      {
        heading: "About Course",
        content:
          "The **GCSE Easter Revision Course: Maths Intensive Refresher** is designed to help students strengthen their understanding of key mathematical concepts and build confidence for their GCSE exams. This comprehensive 10-day program focuses on both concept consolidation and past paper mastery, providing a structured approach to revision. Delivered by an experienced tutor in a small-group setting, the course ensures personalized attention, tailored instruction, and collaborative learning. With expert guidance, comprehensive resources, and proven strategies, students will gain the skills and knowledge needed to tackle even the most challenging exam questions with confidence.",
      },
      {
        heading: "Course Details",
        content: [
          { label: "Course Duration", text: "10 days" },
          { label: "Course Fee", text: "Details about the course fee can be provided as per enrollment inquiries." },
        ],
      },
      // {
      //   heading: "Time Table",
      //   content:
      //     "The **GCSE Easter Revision Course: Maths Intensive Refresher** is a 10-day program designed to strengthen math skills and build exam confidence. Taught by an experienced tutor in small groups, it combines concept review and past paper practice, offering personalized attention, expert guidance, and essential resources to help students excel.",
      //   timetable: [
      //     { label: "Registration", time: "8:30 - 9:00 A.M." },
      //     { label: "Course Start", time: "9:00 A.M." },
      //     { label: "Course Finish", time: "4:00 P.M." },
      //   ],
      // },
      {
        heading: "Course Type",
        content:
          "The course combines small-group learning with expert instruction to ensure a personalized and focused experience. Divided into two parts—concept consolidation and past paper practice—it provides a structured approach to revision. With tailored teaching, collaborative learning, and comprehensive resources, students gain the skills and confidence needed for exam success.",
        types: [
          {
            type: "Content Delivery: Learning Environment",
            details: [
              "Classes are limited to a maximum of 10 students, with an average of 8, allowing for personalized attention and tailored instruction.",
              "Students are grouped by their skill level to foster a collaborative and supportive learning environment.",
              "The course emphasizes active participation through discussions, problem-solving, and worked examples to enhance engagement.",
              "The small class size enables the tutor to address individual needs and provide step-by-step guidance throughout the sessions.",
              "A positive and collaborative atmosphere encourages students to share ideas and gain confidence in their abilities.",
            ],
          },
          // {
          //   type: "Small Group Revision Courses (Classroom-Based Only)",
          //   details: [
          //     "Content: A combination of key topics and topics tailored to the individual needs of each student.",
          //     "When: Take place during school holidays.",
          //     "Where: Delivered in classrooms in our revision centres.",
          //     "Residential: A residential option is available at a number of Easter centres.",
          //     "Length: Typically, 2 days in length for GCSE and 3 days for A Level.",
          //     "Class size: A maximum of 8 students per class (average size is 5 students).",
          //   ],
          // },
        ],
      },
    ],
  },
  // {
  //   img: "e54c0029-5fc0-4841-a79d-cbb075a38f5a",
  //   bgColor : "#518cd6",
  //   rightImage: "d7cdea45-ee03-489d-89b3-73c0618b498d",
  //   name: "KS3 MASTER",
  //   courseCode: "KS3Master",
  //   description:
  //     "Discover the fundamental principles of astrophysics and how they help us understand the universe.",
  //   title: "KS3 Master Course",
  //   sections: [],
  // },
  {
    img: "cc7caa9e-5471-4080-bbff-09fdec720b57",
    rightImage: "d7cdea45-ee03-489d-89b3-73c0618b498d",
    bgColor : "#F7B00B",
    name: "AL MATHS 12",
    courseCode: "ALMaths12",
    description:
      "Our A-Level Mathematics Virtual Tuition program provides 100% online, one-to-one sessions tailored to individual learning needs. Delivered by experienced tutors specializing in popular syllabi such as Edexcel and OCR, the program ensures a thorough understanding of key topics, including statistics, pure mathematics, and mechanics. Students receive personalized attention, exclusive supplementary resources, and a flexible learning environment to help them excel in their academic journey.",
      title: "AL Maths Course 12",
      sections: [
        {
          heading: "About Course",
          content:
            "Our A-Level Mathematics Virtual Tuition program provides 100% online, one-to-one sessions tailored to individual learning needs. Delivered by experienced tutors specializing in popular syllabi such as Edexcel and OCR, the program ensures a thorough understanding of key topics, including statistics, pure mathematics, and mechanics. Students receive personalized attention, exclusive supplementary resources, and a flexible learning environment to help them excel in their academic journey.",
        },
        {
          heading: "Course Details",
          content: [
            { label: "Course Duration", text: " Flexible, typically lasting 1 to 2 hours per session" },
            { label: "Course Fee", text: "Details regarding the course fee are provided upon inquiry, depending on the number of sessions and customization requirements." },
          ],
        },
        // {
        //   heading: "Time Table",
        //   content:
        //     "The **Classes are scheduled based on the student’s availability and convenience.",
        //   timetable: [
        //     { label: "Registration", time: "8:30 - 9:00 A.M." },
        //     { label: "Course Start", time: "9:00 A.M." },
        //     { label: "Course Finish", time: "4:00 P.M." },
        //   ],
        // },
        {
          heading: "Course Type",
          content:
            "Our A-Level Mathematics program offers personalized one-to-one virtual tutoring, providing tailored sessions that cater to each student's individual needs. The course is designed to help students master key topics, using expert instruction, flexible scheduling, and exclusive supplementary resources to ensure academic success and real-world application of mathematical concepts.",
          types: [
            {
              type: "Personalized Tutoring and Curriculum Expertise",
              details: [
                "One-to-One Focus: Each session is customized to address the student's unique learning style, providing personalized attention.",
                "Syllabus Specialization: Tutors are experienced in Edexcel, OCR, and other common A-Level mathematics syllabi, covering topics such as statistics, pure mathematics, and mechanics.",
                // "The course emphasizes active participation through discussions, problem-solving, and worked examples to enhance engagement.",
                // "The small class size enables the tutor to address individual needs and provide step-by-step guidance throughout the sessions.",
                // "A positive and collaborative atmosphere encourages students to share ideas and gain confidence in their abilities.",
              ],
            },
            {
              type: "Flexible Learning Environment and Support",
              details: [
                "Virtual Platform: Students can learn from the comfort of their home, engaging directly with tutors in a flexible, interactive environment.",
                "Exclusive Resources: Access to supplementary materials designed to enhance learning, foster adaptability, and improve problem-solving skills for exams and practical applications.",
                // "Where: Delivered in classrooms in our revision centres.",
                // "Residential: A residential option is available at a number of Easter centres.",
                // "Length: Typically, 2 days in length for GCSE and 3 days for A Level.",
                // "Class size: A maximum of 8 students per class (average size is 5 students).",
              ],
            },
          ],
        },
      ],
  },
  {
    img: "cc7caa9e-5471-4080-bbff-09fdec720b57",
    rightImage: "d7cdea45-ee03-489d-89b3-73c0618b498d",
    bgColor : "#F7B00B",
    name: "AL MATHS 13",
    courseCode: "ALMaths13",
    description:
      "Discover the fundamental principles of astrophysics and how they help us understand the universe.",
    title: "AL Maths Course 13",
    sections: [
      {
        heading: "About Course",
        content:
          "The **Our A-Level Mathematics Virtual Tuition program provides 100% online, one-to-one sessions tailored to individual learning needs. Delivered by experienced tutors specializing in popular syllabi such as Edexcel and OCR, the program ensures a thorough understanding of key topics, including statistics, pure mathematics, and mechanics. Students receive personalized attention, exclusive supplementary resources, and a flexible learning environment to help them excel in their academic journey.",
      },
      {
        heading: "Course Details",
        content: [
          { label: "Course Duration", text: " Flexible, typically lasting 1 to 2 hours per session" },
          { label: "Course Fee", text: "Details regarding the course fee are provided upon inquiry, depending on the number of sessions and customization requirements." },
        ],
      },
      // {
      //   heading: "Time Table",
      //   content:
      //     "The **Classes are scheduled based on the student’s availability and convenience.",
      //   timetable: [
      //     { label: "Registration", time: "8:30 - 9:00 A.M." },
      //     { label: "Course Start", time: "9:00 A.M." },
      //     { label: "Course Finish", time: "4:00 P.M." },
      //   ],
      // },
      {
        heading: "Course Type",
        content:
          "Our A-Level Mathematics program offers personalized one-to-one virtual tutoring, providing tailored sessions that cater to each student's individual needs. The course is designed to help students master key topics, using expert instruction, flexible scheduling, and exclusive supplementary resources to ensure academic success and real-world application of mathematical concepts.",
        types: [
          {
            type: "Personalized Tutoring and Curriculum Expertise",
            details: [
              "One-to-One Focus: Each session is customized to address the student's unique learning style, providing personalized attention.",
              "Syllabus Specialization: Tutors are experienced in Edexcel, OCR, and other common A-Level mathematics syllabi, covering topics such as statistics, pure mathematics, and mechanics.",
              // "The course emphasizes active participation through discussions, problem-solving, and worked examples to enhance engagement.",
              // "The small class size enables the tutor to address individual needs and provide step-by-step guidance throughout the sessions.",
              // "A positive and collaborative atmosphere encourages students to share ideas and gain confidence in their abilities.",
            ],
          },
          {
            type: "Flexible Learning Environment and Support",
            details: [
              "Virtual Platform: Students can learn from the comfort of their home, engaging directly with tutors in a flexible, interactive environment.",
              "Exclusive Resources: Access to supplementary materials designed to enhance learning, foster adaptability, and improve problem-solving skills for exams and practical applications.",
              // "Where: Delivered in classrooms in our revision centres.",
              // "Residential: A residential option is available at a number of Easter centres.",
              // "Length: Typically, 2 days in length for GCSE and 3 days for A Level.",
              // "Class size: A maximum of 8 students per class (average size is 5 students).",
            ],
          },
        ],
      },
    ],
  },
  {
    img: "7af9a11b-a3ba-40e2-b66d-0c3d830a5293",
    rightImage : "d7cdea45-ee03-489d-89b3-73c0618b498d",
    name: "KS4 MATHS",
    bgColor : "#9B59B6",
    courseCode: "KS4 MATHS",
    description:
      "This course information is currently not available but will be updated soon",
    title: "KS4 MATHS",
    sections: [
      // {
      //   heading: "About Course",
      //   content:
      //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text of the printing and typesetting industry.",
      // },
      // {
      //   heading: "Course Details",
      //   content: [
      //     { label: "Course Duration", text: "2 weeks (Monday to Friday)" },
      //     { label: "Course Fee", text: "$300" },
      //   ],
      // },
    ],
  },
];
