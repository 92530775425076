// App.js
import React from "react";
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/animate.css";
import "./css/animate.min.css";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  Home,
  Booking,
  AboutUs,
  Contact,
  PageNotFound,
  CourseMaterials,
  BlogsPage,
  Team,
  Courses,
  ReadBlog,
} from "./pages/index";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import { ToastContainer, toast } from "react-toastify";
import Profile from "./pages/auth/Profile";
import BlogMgt from "./pages/blogMgt/BlogMgt";
import StuMgt from "./pages/stuMgt/StuMgt";
import CourseMaterialsMgt from "./pages/courseMaterialsMgt/CourseMaterialsMgt";
import StuReviewMgt from "./pages/stuReviewMgt/StuReviewMgt";
import MailInquiresMgt from "./pages/mailInquiresMgt/MailInquiresMgt";
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <>
      <div>
        <Router>
          <>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/team" element={<Team />} />
              <Route path="/course" element={<Courses />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/*" element={<PageNotFound />} />
              <Route path="/course-materials" element={<CourseMaterials />} />
              <Route path="/blogs" element={<BlogsPage />} />
              <Route path="/read-blogs" element={<ReadBlog />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/blogs-mgt" element={<BlogMgt />} />

              <Route path="/student-mgt" element={<StuMgt />} />
              <Route
                path="/course-materials-mgt"
                element={<CourseMaterialsMgt />}
              />
              <Route path="/student-review" element={<StuReviewMgt />} />
              <Route path="/mail-inquires" element={<MailInquiresMgt />} />

              <Route path="/profile" element={<Profile />} />
            </Routes>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        </Router>
      </div>
    </>
  );
}
