import React from "react";
import CommonHeading from "../common/CommonHeading";
import { educationQuality } from "../data/Data";

export default function QualityEdu() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <CommonHeading heading="Our specialty" title="Education" subtitle="Quality" />
        </div>
        <div className="row g-4">
          {educationQuality.map((item, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a className="service-item rounded" href="">
                <div
                  className="service-icon rounded p-1"
                  style={{
                    background: item.background,
                    borderRadius : "10px"
                  }}
                >
                  <div className="w-100 h-100 rounded d-flex align-items-center justify-content-center">
                    {item.icon}
                  </div>
                </div>
                <h5 className="mb-3">{item.name}</h5>
                <p className="text-body mb-0">{item.description}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
