import React, { useState, useEffect } from "react";
import { BLOG_ENDPOINT, FILE_UPLOAD_ENDPOINT } from "../../api/endpoints";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "./BlogMgt.css"; // Custom CSS for styling
import ImageLoader from "../../components/common/ImageLoader";
import Sidebar from "../../components/common/Sidebar";
import { apiRequest } from "../../hooks/apiRequest";
import { ToastContainer, toast } from "react-toastify";
import directusClient from "../../api/directusClient";
import { useNavigate } from "react-router-dom";
import BookSpinner from "../../components/common/BookSpinner";

const BlogMgt = () => {
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await directusClient.get("/users/me");
      } catch (err) {
        navigate("/"); // Navigate to home on error
      }
    };
    fetchUserProfile();
  }, []);
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(true); // State for sidebar visibility
  const userRole = localStorage.getItem("user_role") || "";

  const [showConfirmModal, setShowConfirmModal] = useState(false); // New state for confirmation modal
  const [confirmationCallback, setConfirmationCallback] = useState(null); // Store callback for confirmation
  const [submitMessage, setSubmitMessage] = useState("");

  const showConfirmation = (message, callback) => {
    setSubmitMessage(message);
    setConfirmationCallback(() => callback);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    if (confirmationCallback) confirmationCallback();
    setShowConfirmModal(false);
  };

  const handleCancelConfirm = () => {
    setShowModal(true);
    setShowConfirmModal(false);
  };

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const data = await apiRequest(BLOG_ENDPOINT, "GET");
      setBlogs(data.data || []);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      title: "",
      description: "",
      image: "",
    });
    setImageFile(null);
    setIsEditing(false);
    setValidationErrors({});
  };

  const handleShow = (blog = null) => {
    if (blog) {
      setFormData({
        title: blog.title,
        description: blog.description,
        image: blog.image || "",
      });
      setCurrentBlog(blog);
      setImageFile(null);
      setIsEditing(true);
    } else {
      setCurrentBlog(null);
      setImageFile(null);
      setIsEditing(false);
    }
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const validateFields = () => {
    const errors = {};
    if (!formData.title) errors.title = "Title is required.";
    if (!formData.description) errors.description = "Description is required.";
    if (!isEditing && !imageFile)
      errors.image = "Image is required for new blogs.";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const uploadFile = async (file) => {
    const url = `${process.env.REACT_APP_DIRECTUS_URL}${FILE_UPLOAD_ENDPOINT}`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const jsonResponse = await response.json();
      const id = jsonResponse.data.id;
      return id;
    } catch (error) {
      console.error("Error uploading file:", error);
      return "";
    }
  };

  const handleSave = async () => {
    if (!validateFields()) {
      toast.error("Please fix validation errors.");
      return;
    }
    setShowModal(false);

    showConfirmation(
      isEditing
        ? "Are you sure you want to update this blog?"
        : "Are you sure you want to add this blog?",
      async () => {
        try {
          let imageId = formData.image;

          if (imageFile) {
            imageId = await uploadFile(imageFile);
          }

          const blogData = { ...formData, image: imageId };

          const endpoint = isEditing
            ? `${BLOG_ENDPOINT}/${currentBlog.id}`
            : BLOG_ENDPOINT;
          const method = isEditing ? "PATCH" : "POST";

          await apiRequest(endpoint, method, blogData);

          toast.success(
            `Blog ${isEditing ? "updated" : "added"} successfully!`
          );
          handleClose();
          await fetchBlogs();
        } catch (err) {
          toast.error("Error saving blog!");
          setShowModal(true);
          console.error("Error saving blog:", err);
        }
      }
    );
  };

  const handleDelete = (id) => {
    showConfirmation("Are you sure you want to delete this blog?", async () => {
      try {
        setLoading(true);
        await apiRequest(`${BLOG_ENDPOINT}/${id}`, "DELETE");

        toast.success("Blog deleted successfully!");
        await fetchBlogs();
        setLoading(false);
      } catch (err) {
        toast.error("Error deleting blog.");
        setLoading(false);
        console.error("Error deleting blog:", err);
      }
    });
  };

  if (loading) return <BookSpinner />;

  if (error)
    return (
      <div className="error-message">Error loading data: {error.message}</div>
    );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Container fluid>
        <Row>
          <Col md={2} className="p-3 d-none d-md-block">
            <Sidebar role={userRole} />
          </Col>
          <Col
            md={2}
            className={`p-3 d-md-none ${sidebarOpen ? "d-block" : "d-none"}`}
          >
            <Sidebar role={userRole} />
          </Col>
          <Col md={10} className="p-3">
            <Card className="p-3">
              <div className="d-flex justify-content-between align-items-center">
                <h1>BLOGS</h1>
                <button
                  type="button"
                  className="navbar-toggler bg-light"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </Card>

            <Card className="text-center mt-3">
              <h2 className="m-3">Blog Management</h2>

              <Button 
                style={{
                  backgroundColor: "lightblue",
                  borderColor: "lightblue",
                  color: "black",
                  fontWeight: "bold", // Add this line to make the text bold
                }}
                variant="secondary"
                className="m-3"
                onClick={() => handleShow()}
              >
                Add New Blog
              </Button>

              <Row>
                {blogs.length > 0 ? (
                  blogs.map((blog) => (
                    <Col
                      xs={12}
                      md={6}
                      lg={3}
                      key={blog.id}
                      className="m-4"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Card
                        className="shadow-sm"
                        style={{
                          width: "100%",
                          height: "500px", // Fixed height for cards
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          border: "1px solid lightgray",
                        }}
                      >
                        {blog.image && (
                          <div>
                            <ImageLoader
                              imageId={blog.image}
                              style={{ width: "100%" }}
                            />
                          </div>
                        )}
                        <Card.Body
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: "1rem",
                          }}
                        >
                          <Card.Title
                            style={{
                              fontSize: "1.2rem",
                              textAlign: "center",
                              marginBottom: "0.5rem",
                            }}
                          >
                            {blog.title}
                          </Card.Title>
                          <Card.Text
                            style={{
                              flexGrow: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3, // Limits to 3 lines of text
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {blog.description}
                          </Card.Text>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              variant="warning"
                              className="m-2"
                              onClick={() => handleShow(blog)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              className="m-2"
                              onClick={() => handleDelete(blog.id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div className="d-flex justify-content-center p-5">
                    No blogs available
                  </div>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal for adding/editing blog */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Blog" : "Add New Blog"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBlogTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                isInvalid={validationErrors.title}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBlogDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                isInvalid={validationErrors.description}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBlogImage">
              <Form.Label>Image</Form.Label>
              <br></br>
              {formData.image && !imageFile && (
                <ImageLoader
                  imageId={formData.image}
                  style={{ padding: "10px", marginBottom: "10px" }}
                />
              )}
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
                isInvalid={validationErrors.image}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.image}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {isEditing ? "Save Changes" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{submitMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlogMgt;
