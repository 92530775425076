// import React from "react";
// import { testimonial } from "../data/Data";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// export default function Sliders() {
//   const settings = {
//     centerMode: true,
//     centerPadding: "0px",
//     dots: true, // Enable dots navigation
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//     appendDots: (dots) => (
//       <div
//         style={{
//           position: "absolute",
//           bottom: "-30px",
//           display: "flex",
//           justifyContent: "center",
//         }}
//       >
//         <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
//       </div>
//     ),
//     customPaging: (i) => (
//       <div
//         style={{
//           width: "12px",
//           height: "12px",
//           borderRadius: "50%",
//           backgroundColor: "#ccc",
//           margin: "0 5px",
//           cursor: "pointer",
//         }}
//       ></div>
//     ),
//   };

//   return (
//     <div
//       className="container-xxl testimonial my-5 py-5"
//       style={{ position: "relative" }}
//     >
//       <div className="container">
//         <h3
//           className="section-title text-start text text-uppercase"
//           style={{ fontSize: "20px" }}
//         >
//           Student review
//         </h3>
//         <h1 className="mb-4 text-primary text-uppercase">
//           STUDENTS
//           <span className="text-white d-block mt-1">Made us Value</span>
//         </h1>

//         <div className="testimonial-carousel py-5 p-4">
//           <Slider {...settings}>
//             {testimonial.map((item, key) => (
//               <div
//                 key={key}
//                 className="testimonial-item position-relative shadow-lg text-white py-3"
//                 style={{
//                   borderRadius: "15px",
//                   overflow: "hidden",
//                   padding: "30px 20px",
//                   margin: "0 10px",
//                   transform: key === 1 ? "scale(1.1)" : "scale(0.9)", // Scale middle card larger
//                   opacity: key === 1 ? 1 : 0.7, // Make side cards slightly transparent
//                   transition: "transform 0.3s ease, opacity 0.3s ease",
//                 }}
//               >
//                 <div className="d-flex align-items-center">
//                   <img
//                     className="img-fluid rounded-circle"
//                     src={item.img}
//                     alt={`${item.name}'s testimonial`}
//                     style={{
//                       width: "50px",
//                       height: "50px",
//                       border: "2px solid #fff",
//                     }}
//                   />
//                   <div className="ps-3">
//                     <h6 className="fw-bold mb-1" style={{ color: "#333" }}>
//                       {item.name}
//                     </h6>
//                     <small style={{ color: "black" }}>{item.profession}</small>
//                     <div className="mt-1">
//                       {[...Array(item.rating)].map((_, i) => (
//                         <small
//                           key={i}
//                           className="fa fa-star text-warning"
//                         ></small>
//                       ))}
//                       {[...Array(5 - item.rating)].map((_, i) => (
//                         <small
//                           key={i}
//                           className="fa fa-star text-muted"
//                         ></small>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//                 <p
//                   className="mb-3"
//                   style={{ fontStyle: "italic", fontSize: "0.9rem" }}
//                 >
//                   "{item.title}"
//                 </p>
//                 <p
//                   className="mb-3"
//                   style={{ fontStyle: "italic", fontSize: "0.9rem" }}
//                 >
//                   {item.description}
//                 </p>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiRequest } from "../../hooks/apiRequest"; // Replace with your API hook
import { STU_REVIEW_ENDPOINT } from "../../api/endpoints"; // Replace with your API endpoint
import ImageLoader from "../common/ImageLoader";

export default function Sliders() {
  const [reviews, setReviews] = useState([]);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: true, // Enable dots navigation
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "-30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "#ccc",
          margin: "0 5px",
          cursor: "pointer",
        }}
      ></div>
    ),
  };

  // Fetch reviews from the API
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await apiRequest(STU_REVIEW_ENDPOINT);
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div
      className="container-xxl testimonial my-5 py-5"
      style={{ position: "relative" }}
    >
      <div className="container">
        <h3
          className="section-title text-start text text-uppercase"
          style={{ fontSize: "20px" }}
        >
          Student review
        </h3>
        <h1 className="mb-4 text-primary text-uppercase">
          STUDENTS
          <span className="text-white d-block mt-1">Made us Value</span>
        </h1>

        <div className="testimonial-carousel py-5 p-4">
          <Slider {...settings}>
            {reviews.map((item, key) => (
              <div
                key={key}
                className="testimonial-item position-relative shadow-lg text-white py-3"
                style={{
                  borderRadius: "15px",
                  overflow: "hidden",
                  padding: "30px 20px",
                  margin: "0 10px",
                  transform: key === 1 ? "scale(1.1)" : "scale(0.9)", // Scale middle card larger
                  opacity: key === 1 ? 1 : 0.7, // Make side cards slightly transparent
                  transition: "transform 0.3s ease, opacity 0.3s ease",
                }}
              >
                <div className="d-flex align-items-center">
                  {/* <img
                    className="img-fluid rounded-circle"
                    src={item.img}
                    alt={`${item.name}'s testimonial`}
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "2px solid #fff",
                    }}
                  /> */}
                  <ImageLoader
                    imageId={item.stu_image}
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "2px solid #fff",
                    }}
                  />
                  <div className="ps-3">
                    <h6 className="fw-bold mb-1" style={{ color: "#333" }}>
                      {item.name}
                    </h6>
                    <small style={{ color: "black" }}>{item.profession}</small>
                    <div className="mt-1">
                      {[...Array(parseInt(item.rate_star))].map((_, i) => (
                        <small
                          key={i}
                          className="fa fa-star text-warning"
                        ></small>
                      ))}
                      {[...Array(5 - item.rate_star)].map((_, i) => (
                        <small
                          key={i}
                          className="fa fa-star text-muted"
                        ></small>
                      ))}
                    </div>
                  </div>
                </div>
                <p
                  className="mb-3"
                  style={{ fontStyle: "italic", fontSize: "0.9rem" }}
                >
                  "{item.review_comment}"
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
