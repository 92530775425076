import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { navList } from "../data/Data";
import { ROLE_ADMIN, ROLE_STUDENT } from "../../api/roles";
import directusClient from "../../api/directusClient";

export default function Header() {
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [user, setUser] = useState("");

  const handleMouseEnter = (itemId) => {
    setActiveDropdown(itemId);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  useEffect(() => {
    const userRole = localStorage.getItem("user_role") || "";
    const email = localStorage.getItem("email") || "";

    if (userRole === ROLE_ADMIN) {
      setUser("ADMIN");
    } else if (userRole === ROLE_STUDENT) {
      setUser(email);
    } else {
      setUser("");
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_role");
    localStorage.removeItem("email");

    delete directusClient.defaults.headers.common["Authorization"];
    window.location.href = "/";
  };

  return (
    <div
      className="container-fluid bg-light px-0 fixed-top shadow"
      style={{ zIndex: 1030 }}
    >
      <div className="row gx-0 align-items-center">
        {/* Left: Logo */}
        <div className="col-lg-3 d-none d-lg-flex justify-content-center">
          <Link to="/" className="navbar-brand">
            <img
              src="/assets/img/logo.png"
              alt="Logo"
              className="img-fluid"
            />
          </Link>
        </div>

        {/* Center and Right: Navigation Items and Buttons */}
        <div className="col-lg-9">
          <nav className="navbar navbar-expand-lg navbar-light bg-light p-3 p-lg-0">
            <Link to="/" className="navbar-brand d-block d-lg-none">
              <img
                src="/assets/img/logo.png"
                alt="Logo"
                className="img-fluid"
              />
            </Link>
            <button
              type="button"
              className="navbar-toggler"
              onClick={() => setNavbarCollapse(!navbarCollapse)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`${
                navbarCollapse
                  ? "navbar-collapse show justify-content-end"
                  : "collapse navbar-collapse justify-content-end"
              }`}
            >
              <ul className="navbar-nav me-auto">
                {navList.map((item, index) => (
                  <li
                    key={index}
                    className="nav-item"
                    onMouseEnter={() =>
                      item.subItems && handleMouseEnter(item.id)
                    }
                    onMouseLeave={() => item.subItems && handleMouseLeave()}
                  >
                    {item.subItems ? (
                      <>
                        <Link
                          className="nav-link dropdown-toggle mx-2"
                          style={{ color: "var(--primary)",pointerEvents: "none" }}
                          to="#"
                          
                        >
                          {item.text}
                        </Link>
                        <div
                          className={`dropdown-menu rounded-0 m-0 ${
                            activeDropdown === item.id ? "show" : ""
                          }`}
                        >
                          {item.subItems.map((sub, subIndex) => (
                            <Link
                              key={subIndex}
                              to={sub.path}
                              className="dropdown-item "
                              style={{ color: "var(--primary)" }}
                            >
                              {sub.text}
                            </Link>
                          ))}
                        </div>
                      </>
                    ) : (
                      <Link
                        to={item.path}
                        className="nav-link mx-2"
                        style={{ color: "var(--primary)" }}
                      >
                        {item.text}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              {/* Login and Register Buttons */}
              <div className="d-flex">
                {user === "" && (
                  <a
                    className="btn btn-sm btn-primary rounded py-2 px-4 me-2"
                    href="/login"
                  >
                    Login
                  </a>
                )}
                <a
                  className="btn btn-sm btn-outline-primary rounded py-2 px-4 mx-2"
                  href="/register"
                >
                  Register
                </a>
                {user !== "" && (
                  <a
                    className="btn btn-sm btn-primary rounded py-2 px-4 me-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                )}
                <a className="btn btn-sm rounded py-2 px-4 mx-2" href="/">
                  {user}
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
