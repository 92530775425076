import React, { useState } from "react";
import Heading from "../components/common/Heading";
import CommonHeading from "../components/common/CommonHeading";
import { contact } from "../components/data/Data";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import { ToastContainer, toast } from "react-toastify"; // For toast notifications
import "react-toastify/dist/ReactToastify.css";
import { MAIL_INQUIRY_ENDPOINT } from "../api/endpoints";
import { apiRequest } from "../hooks/apiRequest";

export default function Contact() {
  const [newInquiry, setNewInquiry] = useState({
    email: "",
    message: "",
    name: "",
    phone_number: "",
  });

  // Handle changes in the new inquiry form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewInquiry({ ...newInquiry, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiRequest(MAIL_INQUIRY_ENDPOINT, "POST", newInquiry);
      toast.success("Inquiry sent successfully!");
      setNewInquiry({ email: "", message: "", name: "", phone_number: "" }); // Reset form fields
    } catch (error) {
      toast.error("Failed to send inquiry.");
    }
  };

  return (
    <>
      <Header />
      <Heading
        heading="Contact"
        title="Home"
        subtitle="Contact"
        description={
"Ready to unlock your child’s full potential? Whether it’s private one-to-one sessions or engaging group tuition, our tailored approach in Science and Maths ensures success. Join the countless families in Buckinghamshire and Hertfordshire who trust us to deliver results. Get in touch today to learn more and secure your child’s brighter future!"        }
        img={"/assets/img/contactbg.png"}
      />

      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading
            heading="Contact Us"
            title="in touch"
            subtitle="LETS'S GET"
          />
          <div className="row g-4">
            <div className="col-6">
              <div className="row gy-2">
                <div className="row-md-4">
                  <p>
                    Questions, comments, or suggestions? <br /> Simply fill in the form and we’ll be in touch shortly.
                  </p>
                </div>
              </div>
              <div className="row gy-2">
                {contact.map((item, index) => (
                  <div className="row-md-4" key={index}>
                    <p>
                      {item.icon}
                      {item.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={newInquiry.name}
                          onChange={handleChange}
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={newInquiry.email}
                          onChange={handleChange}
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="phone_number"
                          name="phone_number"
                          value={newInquiry.phone_number}
                          onChange={handleChange}
                          placeholder="Phone Number"
                        />
                        <label htmlFor="phone_number">Phone Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          name="message"
                          value={newInquiry.message}
                          onChange={handleChange}
                          style={{ height: "150px" }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn w-100 py-2 rounded-pill text-white"
                        type="submit"
                        style={{ background: "#004380" }}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </>
  );
}
