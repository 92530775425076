import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Blogs from "../components/home/Blogs";
import ImageLoader from "../components/common/ImageLoader";
import CommonHeading from "../components/common/CommonHeading";

export default function ReadBlog() {
  const { state } = useLocation();
  const blog = state?.blog;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Split the blog title into subtitle and title
  const [subtitle, ...titleParts] = blog.title.split(" ");
  const title = titleParts.join(" ");

  if (!blog) {
    return (
      <>
        <Header />
        <div className="container text-center py-5">
          <h2>Blog not found!</h2>
          <p>Please go back and select a blog to view.</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="container-xxl py-5" style={{marginTop: "80px"}}>
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <CommonHeading
              heading="Blogs"
              title={title}
              subtitle={subtitle.toUpperCase()}
            />
          </div>
          <div
            className="d-flex align-items-center py-1"
            style={{
              backgroundColor: "#f8f9fa",
            }}
          >
            <img
              className="img-fluid rounded-circle"
              src={"../assets/img/testimonial-1.jpg"}
              alt={`${blog.userImg}'s testimonial`}
              style={{
                width: "40px",
                height: "40px",
                border: "2px solid #fff",
                marginRight: "10px",
              }}
            />
            <div>
              <h6 className="m-0 p-1" style={{ fontSize: "0.9rem", color: "#333" }}>
                {"ASHANI GAMAGE"}
              </h6>
              <small className="text-muted" style={{ fontSize: "0.8rem" }}>
                {blog.date_created}
              </small>
            </div>
          </div>
          <ImageLoader
            imageId={blog.image}
            style={{
              width: "50%",
    height: "auto",
    objectFit: "cover",
    borderRadius: "10px",
    marginBottom: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
            }}
          />
          <p style={{ fontSize: "1.2rem", lineHeight: "1.8" }}>
            {blog.description}
          </p>
        </div>
      </div>
      <Blogs />
      <Footer />
    </>
  );
}
