import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CommonHeading from "../common/CommonHeading";
import { blogs } from "../data/Data";
import ImageLoader from "../common/ImageLoader";
import { BLOG_ENDPOINT } from "../../api/endpoints";
import { apiRequest } from "../../hooks/apiRequest";
import BookSpinner from "../common/BookSpinner";

export default function Blogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const data = await apiRequest(BLOG_ENDPOINT, "GET");
      setBlogs(data.data || []);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleReadMore = (blog) => {
    navigate("/read-blogs", { state: { blog } });
    window.scrollTo(0, 0);
  };

  if (loading) return <BookSpinner />;

  if (error)
    return (
      <div className="error-message">Error loading data {error.message}</div>
    );

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <CommonHeading
            heading="Latest Blogs"
            title="Blogs"
            subtitle="LATEST"
          />
        </div>
        <div className="row g-4">
          {blogs.map((item, index) => (
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
              key={index}
            >
              <div
                className="service-item rounded shadow-sm"
                style={{
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <ImageLoader
                  imageId={item.image}
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />

                <div
                  className="d-flex align-items-center py-3 px-3"
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <img
                    className="img-fluid rounded-circle"
                    src={"../assets/img/testimonial-1.jpg"}
                    alt={`${item.userImg}'s testimonial`}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "2px solid #fff",
                      marginRight: "10px",
                    }}
                  />
                  <div>
                    <h6
                      className="m-0"
                      style={{ fontSize: "0.9rem", color: "#333" }}
                    >
                      {"ASHANI GAMAGE"}
                    </h6>
                    <small
                      className="text-muted"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {/* {item.date_created} */}
                    </small>
                  </div>
                </div>

                <div className="p-3" style={{ flex: 1 }}>
                  <h5
                    className="mb-2"
                    style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                  >
                    {item.title}
                  </h5>
                  <p className="text-muted mb-0" style={{ fontSize: "0.9rem" }}>
                    {item.description.split(" ").slice(0, 50).join(" ") +
                      (item.description.split(" ").length > 50 ? "..." : "")}
                  </p>
                </div>

                <button
                  className="btn"
                  style={{
                    backgroundColor: "#004380",
                    color: "#fff",
                    borderRadius: "5px",
                    padding: "10px 0",
                    margin: "0",
                    textAlign: "center",
                  }}
                  onClick={() => handleReadMore(item)}
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
