export default function CommonHeading({ heading, title, subtitle }) {
  return (
    <>
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6
          className="section-title text-center text text-uppercase"
          style={{ color: "#004380" }}
        >
          {heading}
        </h6>
        <h1 className="mb-5" style={{ color: "#004380" }}>
          {subtitle}
          <span className="text-primary text-uppercase m-2">{title}</span>
        </h1>
      </div>
    </>
  );
}
