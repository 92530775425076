export default function Newsletter() {
  return (
    <>
      <div
        className="container newsletter mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row justify-content-center">
          <div className="col-lg-10 border rounded p-1">
            <div className="border rounded text-center p-1">
              <div className="bg-white rounded text-center p-5">
                <h4 className="m-1">
                  
                  <span
                    className="text text-uppercase"
                    style={{ color: "#4B6BFB" }}
                  >
                    Student Request
                  </span>
                </h4>
                <p className="">Get blog articles and offers via email</p>
                <div
                  className="position-relative mx-auto"
                  style={{ maxWidth: "400px" }}
                >
                  <input
                    className="form-control w-100 py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <button
                    type="button"
                    className="btn py-2 px-3 position-absolute top-0 end-0 mt-2 me-2"
                    style={{ backgroundColor: "#4B6BFB", color: "white" }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
