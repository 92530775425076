import React from "react";

export default function WhoWeAre() {
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="col-9">
                <img
                  className="img-fluid rounded w-100 wow zoomIn"
                  data-wow-delay="0.3s"
                  src="/assets/img/whoweare1.png"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h6 className="section-title text-start text text-uppercase">
                Who we are
              </h6>
              <h1 className="mb-4">
                ASHANI{" "}
                <span className="text-primary text-uppercase ml-1">Gamage</span>
                <p className="text-primary text-uppercase"> Tuition</p>
              </h1>
              <p className="mb-1">Welcome to our website!</p>
              <p className="mb-4">
                With almost two decades of experience in
                tutoring for GCSEs and A-levels, I am dedicated to helping
                students achieve success in their academic pursuits.
                Specializing in GCSE maths, A-level maths, and GCSE science
                courses, I have honed my teaching methods to ensure that each
                student reaches their full potential. Having worked with over
                500 students, I am proud to maintain a proven track record of
                success. For GCSEs, my students have achieved grades ranging
                from 4 to 9, with a 100% pass rate. In A-level maths, the
                minimum grade my students attain is a B, setting them on a path
                towards excellence. Similarly, for GCSE science, my students
                consistently achieve grades between 5 to 9, with an average
                success rate exceeding 80%. I am committed to providing
                high-quality education, personalized support, and a nurturing
                learning environment to help every student excel in their exams.
                Join me on this journey towards academic achievement and unlock
                your full potential with confidence.
              </p>

              <a
                className="btn py-2 px-3 mt-1 text-white"
                style={{ background: "#004380", borderRadius: "10px" }}
                href="/about"
              >
                Explore More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
