import { Link } from "react-router-dom";
import { footerContact, footerItem } from "../data/Data";
import Newsletter from "../home/Newsletter";
import SocialIcons from "./SocialIcons";
export default function Footer() {
  return (
    <>
      <Newsletter />
      <div
        className="container-fluid text-light footer wow fadeIn"
        style={{ background: "#004380" }}
        data-wow-delay="0.1s"
      >
        <div className="container pb-5">
          <div className="row g-5">
            <div className="col-md-4 col-lg-4">
              <div className="rounded p-4" style={{ background: "#004370" }}>
                <Link to="/">
                  <h1 className="text-white text-uppercase mb-3">
                    ASHANI GAMAGE EDUCATION
                  </h1>
                </Link>
                <p className="text-white mb-0">
                  Build a professional website for your tution Manegemt
                </p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <h6 className="section-title text-start text-white text-uppercase mb-4">
                Contact
              </h6>
              {footerContact.map((val, index) => (
                <p className="mb-2" key={index}>
                  {val.icon} {val.name}
                </p>
              ))}
              <div className="d-flex pt-2">
                <SocialIcons />
              </div>
              <div className="d-flex pt-2" style={{"fontSize" : "0.8rem"}}>
                METADEW TECHNOLOGIES<br></br> Copyright © 2024. 
                All rights reserved.
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="row gy-5 g-4">
                {footerItem.map((section, sectionIndex) => (
                  <div className="col-md-6" key={sectionIndex}>
                    <h6 className="section-title text-start text-white text-uppercase mb-4">
                      {section.header}
                    </h6>
                    {section.UnitItem.map((item, itemIndex) => (
                      <a className="btn btn-link" href="" key={itemIndex}>
                        {item.name}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
