// import React from "react";
// import { Link } from "react-router-dom";

// export default function Heading({
//   heading,
//   title,
//   subtitle,
//   img,
//   description,
// }) {
//   return (
//     <div
//       className="container-fluid page-header mb-5 px-0"
//       style={{
//         background: `url(${img}) no-repeat center center/cover`,
//       }}
//     >
//       <div className="container page-header-inner py-5" style={{"marginRight" : "0px"}}>
//         <div className="row align-items-center">
//           {/* Left Column */}
//           <div className="col-md-6 text-center text-md-start pb-3"></div>

//           {/* Right Column */}
//           <div className="col-md-6 text-center text-md ">
//             <h1 className="display-3 text-white mb-3 animated slideInDown">
//               {heading}
//             </h1>

//             <nav aria-label="breadcrumb">
//               <ol className="breadcrumb justify-content-center justify-content-md text-uppercase">
//                 <li className="breadcrumb-item">
//                   <Link to="/" className="text-white">
//                     {title}
//                   </Link>
//                 </li>
//                 <li
//                   className="breadcrumb-item text-white active"
//                   aria-current="page"
//                 >
//                   {subtitle}
//                 </li>
//               </ol>
//             </nav>

//             {description && <p className="text-white mb-4">{description}</p>}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React from "react";
import { Link } from "react-router-dom";

export default function Heading({
  heading,
  title,
  subtitle,
  img,
  description,
}) {
  return (
    <div
      className="container-fluid page-header mb-5 px-0"
      style={{
        background: `url(${img}) no-repeat center center/cover`,
      }}
    >
      <div className="container page-header-inner py-5" style={{ marginRight: "0px"}}>
        <div className="row align-items-center">
          {/* Left Column */}
          <div className="col-md-6 text-center text-md-start pb-3"></div>

          {/* Right Column */}
          <div
            className="col-md-6 d-flex flex-column align-items-center justify-content-center text-center"
            style={{ minHeight: "100vh" }}  // Ensures full viewport height
          >
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              {heading}
            </h1>

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-white">
                    {title}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  {subtitle}
                </li>
              </ol>
            </nav>

            {description && <p className="text-white mb-4">{description}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
