import React, { useState, useEffect } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import CommonHeading from "../components/common/CommonHeading";
import {
  FaFileAlt,
  FaFilePdf,
  FaYoutube,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { apiRequest } from "../hooks/apiRequest";
import {
  COURSE_MATERIAL_ENDPOINT,
  COURSE_MATERIAL_SUB_TOPIC_ENDPOINT,
} from "../api/endpoints";
import { ROLE_ADMIN, ROLE_STUDENT } from "../api/roles";
import BookSpinner from "../components/common/BookSpinner";

const CourseMaterials = () => {
  const [courseMaterials, setCourseMaterials] = useState([]);
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");
  const [loading, setLoading] = useState(true);

  // Fetch course materials and subtopics
  useEffect(() => {
    const fetchCourseMaterials = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(COURSE_MATERIAL_ENDPOINT);
        const courseMaterialsWithSubtopics = await Promise.all(
          response.data.map(async (course) => {
            // Fetch subtopics for each topic
            const subtopicsResponse = await apiRequest(
              `${COURSE_MATERIAL_SUB_TOPIC_ENDPOINT}?filter[topic_id][_eq]=${course.id}`
            );
            return { ...course, subtopics: subtopicsResponse.data };
          })
        );
        setCourseMaterials(courseMaterialsWithSubtopics);
        setLoading(false);
      } catch (error) {
        console.error("Failed to load course materials.", error);
        setLoading(false);
      }
    };
    fetchCourseMaterials();
  }, []);

  const [user, setUser] = useState("");

  useEffect(() => {
    const userRole = localStorage.getItem("user_role") || "";
    const email = localStorage.getItem("email") || "";

    if (userRole === ROLE_ADMIN) {
      setUser("ADMIN");
    } else if (userRole === ROLE_STUDENT) {
      setUser(email);
    } else {
      setUser("");
    }
  }, []);

  // Handle expanding/collapsing topics
  const toggleTopic = (index) => {
    setExpandedTopicIndex(expandedTopicIndex === index ? null : index);
  };

  // Handle video icon click
  const handleVideoClick = (link) => {
    console.log(link);
    setCurrentVideo(link);
    setShowVideoModal(true);
  };

  // Close video modal
  const closeVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideo("");
  };

  if (loading) return <BookSpinner />;

  return (
    <div>
      <Header />
      <div className="container-fluid page-header p-0" style={{marginTop: "150px"}}>
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <CommonHeading
            heading="Course Materials"
            title="Materials"
            subtitle="COURSE"
          />
        </div>
        <div className="course-materials">
          {courseMaterials.map((topic, index) => (
            <div key={index} className="topic">
              <div className="topic-header" onClick={() => toggleTopic(index)}>
                <h6>{topic.topic}</h6>
                <span>
                  {expandedTopicIndex === index ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
              </div>

              {expandedTopicIndex === index && (
                <div className="subtopics-list">
                  {topic.subtopics.map(
                    (subtopic) =>
                      // Check if login is needed and if the user is not empty
                      (!subtopic.login_need || user != "") && (
                        <div key={subtopic.id} className="subtopic-item">
                          <span>{subtopic.title}</span>
                          <div className="icons">
                            {subtopic.doc_url && (
                              <a
                                href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${subtopic.doc_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaFileAlt className="icon" color="blue" />
                              </a>
                            )}
                            {subtopic.pdf_url && (
                              <a
                                href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${subtopic.pdf_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaFilePdf className="icon" color="orange" />
                              </a>
                            )}

                            {subtopic.video_url && (
                              <span
                                onClick={() =>
                                  handleVideoClick(subtopic.video_url)
                                }
                              >
                                <FaYoutube className="icon" color="red" />
                              </span>
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Video Modal */}
        {showVideoModal && (
          <div className="video-modal" onClick={closeVideoModal}>
            <div className="video-content" onClick={(e) => e.stopPropagation()}>
              <iframe
                src={currentVideo}
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        )}
        <Footer />

        {/* Styles */}
        <style jsx>{`
          .course-materials {
            padding: 20px;
          }
          .topic {
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            margin-bottom: 10px;
            padding: 10px;
          }
          .topic-header {
            font-weight: bold;
            font-size: 1.2em;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .subtopics-list {
            margin-top: 10px;
          }
          .subtopic-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            border-bottom: 1px solid #ececec;
          }
          .subtopic-item:last-child {
            border-bottom: none;
          }
          .icons {
            display: flex;
            gap: 10px;
          }
          .icon {
            font-size: 1.5em;
            cursor: pointer;
          }
          .video-modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .video-content {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            max-width: 80%;
            max-height: 80%;
          }
        `}</style>
      </div>
    </div>
  );
};

export default CourseMaterials;
