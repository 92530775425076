// import React from "react";
// import { Container, Row, Col, Card } from "react-bootstrap";
// import "./Dashboard.css"; // Custom CSS file for styling

// const DashboardCards = () => {
//   const cards = [
//     { title: "Total Students", count: 50, bgColor: "card-green", icon: "🏁" },
//     { title: "Total Blogs", count: 50, bgColor: "card-blue", icon: "👀" },
//     { title: "Pending Inquiries", count: 50, bgColor: "card-teal", icon: "👍" },
//     { title: "Total Reviews", count: 50, bgColor: "card-purple", icon: "🏅" },
//   ];

//   return (
//       <Row className="mt-4">
//         {cards.map((card, index) => (
//           <Col md={3} key={index}>
//             <Card className={`dashboard-card ${card.bgColor}`}>
//               <div className="card-icon">{card.icon}</div>
//               <h5>{card.title}</h5>
//               <h2>{card.count}</h2>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//   );
// };

// export default DashboardCards;
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { apiRequest } from "../../hooks/apiRequest"; // Replace with your API hook
import {
  BLOG_ENDPOINT,
  MAIL_INQUIRY_ENDPOINT,
  STU_REVIEW_ENDPOINT,
  STUDENT_API_ENDPOINT,
} from "../../api/endpoints"; // Replace with your API endpoint imports
import "./Dashboard.css"; // Custom CSS file for styling

const DashboardCards = () => {
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [pendingInquiries, setPendingInquiries] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);

  // Fetch data for each section
  useEffect(() => {
    // Fetch total students
    const fetchTotalStudents = async () => {
      try {
        const response = await apiRequest(STUDENT_API_ENDPOINT);
        setTotalStudents(response.data.length); // Adjust depending on the response structure
      } catch (error) {
        console.error("Error fetching total students:", error);
      }
    };

    // Fetch total blogs
    const fetchTotalBlogs = async () => {
      try {
        const response = await apiRequest(BLOG_ENDPOINT);
        setTotalBlogs(response.data.length); // Adjust depending on the response structure
      } catch (error) {
        console.error("Error fetching total blogs:", error);
      }
    };

    // Fetch pending inquiries
    const fetchPendingInquiries = async () => {
      try {
        const response = await apiRequest(MAIL_INQUIRY_ENDPOINT);
        setPendingInquiries(response.data.filter((inquiry) => !inquiry.isResolved).length); // Adjust depending on the response structure
      } catch (error) {
        console.error("Error fetching pending inquiries:", error);
      }
    };

    // Fetch total reviews
    const fetchTotalReviews = async () => {
      try {
        const response = await apiRequest(STU_REVIEW_ENDPOINT);
        setTotalReviews(response.data.length); // Adjust depending on the response structure
      } catch (error) {
        console.error("Error fetching total reviews:", error);
      }
    };

    fetchTotalStudents();
    fetchTotalBlogs();
    fetchPendingInquiries();
    fetchTotalReviews();
  }, []);

  const cards = [
    { title: "Total Students", count: totalStudents, bgColor: "card-green", icon: "🏁" },
    { title: "Total Blogs", count: totalBlogs, bgColor: "card-blue", icon: "👀" },
    { title: "Pending Inquiries", count: pendingInquiries, bgColor: "card-teal", icon: "👍" },
    { title: "Total Reviews", count: totalReviews, bgColor: "card-purple", icon: "🏅" },
  ];

  return (
    <Row className="mt-4">
      {cards.map((card, index) => (
        <Col md={3} key={index}>
          <Card className={`dashboard-card ${card.bgColor}`}>
            <div className="card-icon">{card.icon}</div>
            <h5>{card.title}</h5>
            <h2>{card.count}</h2>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default DashboardCards;
