import React from "react";
import ImageLoader from "../common/ImageLoader";
import { courses } from "../data/Data";

export default function CourseDetails({ courseCode }) {
  const course =
    courses.find((course) => course.courseCode === courseCode) || {};

  return (
    <div  className="container">

    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "0px",
      }}
    >
      <div
        className="course-card"
        style={{
          backgroundColor: course.bgColor,
          borderRadius: "20px",
          padding: "20px",
          display: "flex",
          flexDirection: "row", // For desktop view
          flexWrap: "wrap", // For responsiveness
          alignItems: "center",
          color: "white",
          // maxWidth: "800px", // Optional width limit for larger screens
          width: "100%", // Full width on mobile
        }}
      >
        {/* Text Section */}
        <div style={{ flex: 1, padding: "10px 20px", minWidth: "250px" }}>
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "28px",
              marginBottom: "10px",
            }}
          >
            {course.title?.toUpperCase()}
          </h1>
          <p style={{ fontSize: "16px", fontWeight: "300", lineHeight: "1.6" }}>
            {course.description}
          </p>
        </div>

        {/* Image Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "300px",
            marginTop: "10px", // Spacing for small screens
          }}
        >
          <ImageLoader
            imageId={course.rightImage}
            style={{
              width: "200px",
              height: "200px",
              objectFit: "cover",
              borderRadius: "10px", // Optional for softer edges
              boxShadow: "none", // Explicitly remove box shadow
            }}
          />
        </div>
      </div>
      
    </div>

     {course.sections?.map((section, index) => {
      const [firstWord, ...restWords] = section.heading.split(" ");
      const remainingWords = restWords.join(" ");

      return (
        <div key={index} className="course-section">
          <div className="text-left wow fadeInUp" data-wow-delay="0.1s">
            <h4
              className="section-title"
              style={{ color: "#004380", fontSize: "20px" }}
            >
              {firstWord.toUpperCase()}
              <span className="text-primary text-uppercase m-2">
                {remainingWords}
              </span>
            </h4>
          </div>
          {Array.isArray(section.content) ? (
            section.content.map((item, idx) => (
              <div key={idx}>
                <strong
                  style={{ color: "#022044", fontSize: "15px" }}
                  className="mb-3"
                >
                  {item.label}
                </strong>
                <p>{item.text}</p>
              </div>
            ))
          ) : (
            <p>{section.content}</p>
          )}

          {section.timetable && (
            <div className="timetable">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2">Time Table</th>
                  </tr>
                </thead>
                <tbody>
                  {section.timetable.map((timeItem, i) => (
                    <tr key={i}>
                      <td>{timeItem.label}</td>
                      <td>{timeItem.time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {section.types && (
            <ul className="course-types">
              {section.types.map((type, idx) => (
                <li key={idx}>
                  <strong>{type.type}</strong>
                  <ul>
                    {type.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    })}
    </div>

  );
}
