import React from "react";

export default function StatCards() {
  const stats = [
    { value: "15K", label: "Successful Students" },
    { value: "150K", label: "Monthly Visitors" },
    { value: "100%", label: "5-9 GCSE grades" },
    { value: "80%", label: "7-9 GCSE maths grades" },
  ];

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-4 mt-4">
          {stats.map((stat, index) => (
            <div key={index} className="col-lg-3 col-md-6 text-center">
              <div
                className="stat-box rounded p-4"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h3 className=" mb-2" style={{"color" : "#022044"}}>{stat.value}</h3>
                <p className="text-primary mb-0">{stat.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
