import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import getImageById from "../../hooks/getImageById";


const ImageLoader = ({ imageId, altText, style }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = await getImageById(imageId); // Ensure this function returns the correct URL
        setImageUrl(url);
      } catch (err) {
        setError("Failed to load image");
      }
    };

    if (imageId) {
      fetchImage();
    }
  }, [imageId]);

  // Default styles
  const defaultStyle = {
    width: "200px",
    height: "200px",
    objectFit: "cover", // Keeps the aspect ratio and fills the area
    borderRadius: "8px", // Adds rounded corners
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for better visibility
  };

  // Combine default styles with any custom styles provided
  const combinedStyle = { ...defaultStyle, ...style };

  if (error) return <div>{error}</div>;
  if (!imageUrl) return <div>Loading...</div>;

  return (
    <img src={imageUrl} alt={altText} style={combinedStyle} className="" />
  );
};

ImageLoader.propTypes = {
  imageId: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  style: PropTypes.object,
};

ImageLoader.defaultProps = {
  style: {}, // Default style prop as an empty object
};

export default ImageLoader;
